@use '@angular/material' as mat;
@import "./app/_styles/common";
@import '../node_modules/@swimlane/ngx-datatable/index.css';
@import '../node_modules/@swimlane/ngx-datatable/themes/material.css';
@import '../node_modules/@swimlane/ngx-datatable/assets/icons.css';
@import "../node_modules/@busacca/ng-pick-datetime/assets/style/picker.min.css";
/* You can add global styles to this file, and also import other style files */

* {
  font-feature-settings: "pkna";
  letter-spacing: 0.025em;
}

body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #FFFFFF;
  font-family: "Noto Sans Japanese", "Ubuntu", SansSerif !important;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

h1, h2, h3, h4, h5 {
  font-family: "Noto Sans Japanese", "Ubuntu", SansSerif !important;
  letter-spacing: 0.025em !important;
}

/*
  Material Design Lite style import
*/
//rgb of $dfree-green
$color-primary: 21, 182, 180;
//rgb of $dfree-green-dark
$color-primary-dark: 12, 155, 157;
//rgb of $blue
$color-accent: 20, 126, 224;
@import "../node_modules/@angular-mdl/core/scss/mdl/color-definitions";
$color-primary-contrast: $color-dark-contrast;
$color-accent-contrast: $color-dark-contrast;

@import '../node_modules/@angular-mdl/core/scss/material-design-lite';
@import '../node_modules/@angular-mdl/popover/popover';
@import '../node_modules/@angular-mdl/select/select';
/*
 END
 Material Design Lite style import
*/

button {
  &:focus {
    outline: 0;
  }
}

a {
  text-decoration: none;
}

// 共通view
.h1container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.h1fontStyle {
  font-size: 22px;
  color: $black;
  display: inline-block;
  margin: 0;
}

.btn-with-h1 {
  display: inline-block;
}

.form-free-width .mdl-textfield {
  width: 100%;
}

/** Pagination */
table + pagination-controls > pagination-template {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  .ngx-pagination {
    margin-bottom: 0;
  }
}

.table-bottom-control {
  .ngx-pagination {
    margin-bottom: 0;
  }
}

/* アカウントの検索 */
.account-search {
  .mdl-textfield {

  }
  .mdl-textfield__input {
    border-bottom: none !important;
  }
}

/* datetime picker */
.datetime-picker {
  display: inline-block;
  background: lighten($light-gray, 10);
  padding: 4px 8px;
  border-radius: 6px;
  cursor: pointer;
  .material-icons {
    color: $gray;
    vertical-align: middle;
  }
  &:hover {
    background: lighten($light-gray, 5);
    transition: .2s;
  }
  input {
    border: none;
    cursor: pointer;
    font-size: 14px;
    padding: 8px 0;
    background: yellow;
    background: transparent;
    &:focus {
      outline: 0;
    }
  }

}

.highlight-saturday{
    background: #6699FF;
    border-radius: 100%;
}
.highlight-saturday > .mat-calendar-body-cell-content {
    color: #ffffff;
}

.highlight-sunday{
    background: #FF0000;
    border-radius: 100%;
}

.highlight-sunday > .mat-calendar-body-cell-content {
    color: #ffffff;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

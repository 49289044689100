$black: #232324;
$gray: #8D8F91;
$light-gray: #D7DBDE;
$dfree-green: #15B6B4;
$dfree-green-light: #6FD2D1;
$dfree-green-dark: #0C9B9D;
// $dfree-green-dark: rgb(20, 126, 224);
// $dfree-green-dark: #145fe0;

$light-blue: #70B0EC;
$blue: #147EE0;
$dark-blue: #0F65B7;
$yellow: #ffc843;
$red: #ca3c38;

// Noto weights
$font-thin: 200;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-heavy: 900;

@mixin font-noto() {
  font-family: "Noto Sans Japanese", "Ubuntu", SansSerif !important;
}
